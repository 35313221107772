import { FC } from 'react';

import { ICommonIconProps } from './types';

export const TelegramIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 54,
  height = 54,
  viewBox = '0 0 54 54',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g clipPath="url(#clip0_3_58)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54 27C54 41.9108 41.9108 54 27 54C12.0893 54 0 41.9108 0 27C0 12.0893 12.0893 0 27 0C41.9108 0 54 12.0893 54 27ZM27.9675 19.9328C25.3418 21.024 20.0925 23.2852 12.222 26.7143C10.944 27.2227 10.2735 27.72 10.2128 28.206C10.1093 29.0295 11.1397 29.3535 12.5392 29.7922C12.7305 29.853 12.9285 29.9137 13.131 29.9812C14.5103 30.429 16.3643 30.9532 17.3273 30.9735C18.2025 30.9915 19.179 30.6315 20.2568 29.8935C27.6098 24.9277 31.4055 22.419 31.644 22.365C31.8127 22.3267 32.0467 22.2773 32.2043 22.419C32.3617 22.5585 32.346 22.824 32.3302 22.896C32.2267 23.3302 28.1903 27.0855 26.0977 29.0295C25.4452 29.6348 24.984 30.0645 24.8895 30.1635C24.678 30.3818 24.462 30.591 24.255 30.7913C22.9725 32.0243 22.014 32.9513 24.309 34.4632C25.4115 35.19 26.2935 35.7908 27.1732 36.3893C28.134 37.044 29.0925 37.6965 30.3345 38.511C30.6495 38.718 30.951 38.9318 31.2458 39.141C32.364 39.9398 33.3698 40.6552 34.6118 40.5427C35.3317 40.4752 36.0787 39.798 36.4567 37.7752C37.35 32.9917 39.1095 22.6328 39.5168 18.3623C39.5415 18.0077 39.5264 17.6515 39.4717 17.3003C39.439 17.0166 39.3009 16.7556 39.0848 16.569C38.763 16.3057 38.2635 16.2495 38.0385 16.254C37.0238 16.272 35.4668 16.8143 27.9675 19.9328Z"
        fill="url(#paint0_linear_3_58)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3_58"
        x1="13.5272"
        y1="2.49899"
        x2="45.5795"
        y2="50.6318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D63FF" />
        <stop offset="1" stopColor="#2D63FF" />
      </linearGradient>
      <clipPath id="clip0_3_58">
        <rect width="54" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
