
import s from './Styles.module.scss';
import logo from './images/logo.png'
import {Button} from 'components'

export const Join = ({ prop }) => {
  console.log(prop);
    return (
      <div className={s.root}>
        <div className={s.content}>
          <img src={logo} alt={logo} />
          <div className={s.block}>
            <div className={s.details}>
              <div className={s.header}>Join us!</div>
              <div className={s.text}>
                Find out the detailed conditions for opening your cyber arena
              </div>
              <div className={s.phone}>
                +7 999 345 56 78 <br /> support@greed.ltd
              </div>
            </div>
            <Button>GET ADVICE</Button>
          </div>
        </div>
      </div>
    );
}
