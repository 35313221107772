import cn from 'classnames';
import s from './Styles.module.scss';

export const Button = ({ children, next = false, prev = false, onClick }) => {
  const isSmall = next || prev;
  return (
    <div className={cn(s.button, isSmall && s.small)} onClick={onClick}>
      {children}
    </div>
  );
};
