export const Money = () => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.49414 12.7168H49.5059V38.2832H1.49414V12.7168Z"
      stroke="url(#paint0_linear_49_139)"
      stroke-width="2.98828"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M27.5546 12.7168L6.70899 5.83807L4.45314 12.6743M23.4454 38.2831L44.291 45.1619L46.5609 38.2831"
      stroke="url(#paint1_linear_49_139)"
      stroke-width="2.98828"
      stroke-miterlimit="10"
    />
    <path
      d="M7.66992 12.7168C7.66992 16.1276 4.90496 18.8926 1.49414 18.8926M49.5059 18.8926C46.095 18.8926 43.3301 16.1276 43.3301 12.7168M1.49414 32.1074C4.90496 32.1074 7.66992 34.8723 7.66992 38.2831M43.3301 38.2831C43.3301 34.8723 46.095 32.1074 49.5059 32.1074"
      stroke="url(#paint2_linear_49_139)"
      stroke-width="2.98828"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M25.5 31.7089C28.9291 31.7089 31.7089 28.9291 31.7089 25.5C31.7089 22.0709 28.9291 19.291 25.5 19.291C22.0709 19.291 19.291 22.0709 19.291 25.5C19.291 28.9291 22.0709 31.7089 25.5 31.7089Z"
      stroke="url(#paint3_linear_49_139)"
      stroke-width="2.98828"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M11.7539 25.5H9.42972M41.7032 25.5H39.3789"
      stroke="url(#paint4_linear_49_139)"
      stroke-width="2.98828"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <defs>
      <linearGradient
        id="paint0_linear_49_139"
        x1="25.5"
        y1="12.7168"
        x2="25.5"
        y2="38.2832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#10A492" />
        <stop offset="1" stop-color="#00F4FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_49_139"
        x1="25.507"
        y1="5.83807"
        x2="25.507"
        y2="45.1619"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#10A492" />
        <stop offset="1" stop-color="#00F4FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_49_139"
        x1="25.5"
        y1="12.7168"
        x2="25.5"
        y2="38.2831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#10A492" />
        <stop offset="1" stop-color="#00F4FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_49_139"
        x1="25.5"
        y1="19.291"
        x2="25.5"
        y2="31.7089"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#10A492" />
        <stop offset="1" stop-color="#00F4FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_49_139"
        x1="25.5664"
        y1="25.5"
        x2="25.5664"
        y2="26.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#10A492" />
        <stop offset="1" stop-color="#00F4FF" />
      </linearGradient>
    </defs>
  </svg>
);

export const Briefcase = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.6026 5.62538C46.5997 5.62538 46.5967 5.625 46.5938 5.625H33.8438V4.21875C33.8438 1.89253 31.9511 0 29.625 0H18.375C16.0488 0 14.1562 1.89262 14.1562 4.21875V5.625H1.40625C0.62325 5.625 0 6.26662 0 7.03125V37.9688C0 40.295 1.89262 42.1875 4.21875 42.1875H43.7812C46.1075 42.1875 48 40.2949 48 37.9688V7.05544C47.9462 6.13181 47.3782 5.63044 46.6026 5.62538ZM16.9688 4.21875C16.9688 3.44344 17.5997 2.8125 18.375 2.8125H29.625C30.4003 2.8125 31.0312 3.44344 31.0312 4.21875V5.625H16.9688V4.21875ZM44.6426 8.4375L40.2755 21.5383C40.1825 21.8185 40.0036 22.0622 39.7642 22.2348C39.5248 22.4074 39.237 22.5002 38.9419 22.5H31.0312V21.0938C31.0312 20.317 30.4017 19.6875 29.625 19.6875H18.375C17.5983 19.6875 16.9688 20.317 16.9688 21.0938V22.5H9.05822C8.76304 22.5003 8.47528 22.4075 8.23586 22.2348C7.99643 22.0622 7.81751 21.8185 7.72453 21.5383L3.35747 8.4375H44.6426ZM28.2188 22.5V25.3125H19.7812V22.5H28.2188ZM45.1875 37.9688C45.1875 38.7441 44.5566 39.375 43.7812 39.375H4.21875C3.44344 39.375 2.8125 38.7441 2.8125 37.9688V15.6969L5.05631 22.4278C5.33548 23.2682 5.87241 23.9993 6.59081 24.5172C7.30921 25.035 8.17253 25.3133 9.05813 25.3125H16.9688V26.7188C16.9688 27.4955 17.5983 28.125 18.375 28.125H29.625C30.4017 28.125 31.0312 27.4955 31.0312 26.7188V25.3125H38.9418C39.8274 25.3133 40.6907 25.0351 41.4092 24.5172C42.1276 23.9994 42.6645 23.2683 42.9437 22.4278L45.1875 15.6969V37.9688Z"
      fill="url(#paint0_linear_49_136)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_49_136"
        x1="24"
        y1="0"
        x2="24"
        y2="42.1875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#10A492" />
        <stop offset="1" stop-color="#00F4FF" />
      </linearGradient>
    </defs>
  </svg>
);

export const Chat = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_49_147)">
      <mask
        id="mask0_49_147"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="59"
        height="59"
      >
        <path
          d="M58.6665 58.6667H0V0.000164032H58.6665V58.6667Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_49_147)">
        <path
          d="M17.0924 32.3091C18.2692 38.24 23.501 42.711 29.7779 42.711C32.3732 42.711 34.7898 41.9456 36.8144 40.6302L42.0898 42.0888L40.6312 36.8134C41.9466 34.7876 42.712 32.3722 42.712 29.7769C42.712 23.5 38.241 18.2681 32.3102 17.0914"
          stroke="url(#paint0_linear_49_147)"
          stroke-width="3.43749"
          stroke-miterlimit="10"
        />
        <path
          d="M16.8013 1.2892C8.23394 1.2892 1.28906 8.23408 1.28906 16.8015C1.28906 19.8723 2.18166 22.7357 3.72166 25.1442L1.91125 31.6915L8.45852 29.8811C10.8671 31.42 13.7293 32.3126 16.8013 32.3126C25.3675 32.3126 32.3124 25.3677 32.3124 16.8003C32.3124 8.23294 25.3675 1.2892 16.8013 1.2892Z"
          stroke="url(#paint1_linear_49_147)"
          stroke-width="3.43749"
          stroke-miterlimit="10"
        />
        <path
          d="M18.0903 25.8673H15.5122V23.2892H18.0903V25.8673Z"
          fill="url(#paint2_linear_49_147)"
        />
        <path
          d="M12.9341 12.8908C12.9341 10.7549 14.6654 9.02359 16.8013 9.02359C18.9371 9.02359 20.6684 10.7549 20.6684 12.8908C20.6684 14.0206 20.1838 15.0381 19.4103 15.745L16.8013 18.1329V20.7111"
          stroke="url(#paint3_linear_49_147)"
          stroke-width="3.43749"
          stroke-miterlimit="10"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_49_147"
        x1="29.9022"
        y1="42.711"
        x2="29.9022"
        y2="17.0914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAD3" />
        <stop offset="1" stop-color="#10A492" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_49_147"
        x1="16.8007"
        y1="32.3126"
        x2="16.8007"
        y2="1.2892"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAD3" />
        <stop offset="1" stop-color="#10A492" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_49_147"
        x1="16.8013"
        y1="25.8673"
        x2="16.8013"
        y2="23.2892"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#10A492" />
        <stop offset="1" stop-color="#00F4FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_49_147"
        x1="16.8013"
        y1="20.7111"
        x2="16.8013"
        y2="9.02359"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00CAD3" />
        <stop offset="1" stop-color="#10A492" />
      </linearGradient>
      <clipPath id="clip0_49_147">
        <rect width="44" height="44" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
