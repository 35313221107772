
import s from './Styles.module.scss';
import {Button} from 'components'
import { Briefcase, Chat, Money } from './icons';

export const Main = ({ prop }) => {
  console.log(prop);
    return (
      <div className={s.root}>
        <div className={s.text}>
          Open an international-level computer club in your city
        </div>
        <div className={s.points}>
          <div className={s.point}><Money/>Annual profitability of up to 60%</div>
          <div className={s.point}><Briefcase/>Turnkey ready business, available in just 2 months</div>
          <div className={s.point}><Chat/>Full support after opening</div>
        </div>
        <Button>Consult for free</Button>
      </div>
    );
}
