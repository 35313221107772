import s from './Styles.module.scss';
import balanar from './images/balanar.png';
import { Button } from 'components';
import { useState } from 'react';

export const Plan = ({ prop }) => {
  const questions = [
    'In which city is the opening planned?',
    'How many PCs do you want to use?',
    'Expected opening budget?',
  ];
  const [number, setNumber] = useState(0);
  const [text, setText] = useState('')
  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.title}>Individual business plan</div>
        <div className={s.text}>
          Answer a few questions and we will send you a detailed calculation of
          indicators for your city, with expenses and profitability
        </div>
      </div>
      <div className={s.content}>
        <img src={balanar} alt="balanar" />
        <div className={s.formContainer}>
          <div className={s.title}>Answer 3 simple questions</div>
          <div className={s.form}>
            <div className={s.question}>{questions[number]}</div>
            <div className={s.border} />
            <input value={text} onChange={(e) => setText(e.target.value)}></input>
            <div className={s.btnContainer}>
              <Button
                onClick={() => {
                  if (number > 0) {
                    setNumber(number - 1);
                    setText('')
                  }
                }}
              >
                Prev
              </Button>
              <Button
                onClick={() => {
                  if (number < 2) {
                    setNumber(number + 1);
                  }
                  setText('');
                }}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
