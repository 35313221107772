
import clsx from 'clsx';
import s from './Styles.module.scss';

export const Process = ({ prop }) => {
  console.log(prop);
    return (
      <div className={s.root}>
        <div className={s.headerbig}>WORK PROCESS</div>
        <div className={s.line}>
          <div className={s.point}>
            <div className={s.dot}>1</div>
            <div className={s.block}>
              <div className={s.header}>Preliminary business plan</div>
              <div className={s.title}>After consultation</div>
              <div className={s.hint}>
                We will send you a detailed report with all the expenses for the
                opening of the club, with calculations and formulas
              </div>
            </div>
          </div>
          <div className={s.dotLine} />
          <div className={s.point}>
            <div className={s.dot}>2</div>
            <div className={s.block}>
              <div className={s.header}>Choosing a space</div>
              <div className={s.title}>1-2 weeks</div>
              <div className={s.hint}>
                We will select the best location in your city
              </div>
            </div>
          </div>
          <div className={s.dotLine} />
          <div className={s.point}>
            <div className={s.dot}>3</div>
            <div className={s.block}>
              <div className={s.header}>Design project </div>
              <div className={s.title}>1-2 weeks</div>
              <div className={s.hint}>
                We will do interior and exterior design and make an
                architectural plan
              </div>
            </div>
          </div>
          <div className={s.dotLine} />
          <div className={s.point}>
            <div className={s.dot}>4</div>
            <div className={s.block}>
              <div className={s.header}>Equipment selection</div>
              <div className={s.title}>1-2 days</div>
              <div className={s.hint}>
                We will select an individual configuration according to the
                budget, technical characteristics and discounts
              </div>
            </div>
          </div>
          <div className={s.rightDotLine} />
        </div>
        <div className={clsx(s.line, s.second)}>
          <div className={s.point}>
            <div className={s.dot}>8</div>
            <div className={s.block}>
              <div className={s.header}>Individual business plan</div>
              <div className={s.title}> 2-3 days</div>
              <div className={s.hint}>
                Individual calculations based on your space, repair costs,
                computers, components and available budget
              </div>
            </div>
          </div>
          <div className={s.dotLine} />
          <div className={s.point}>
            <div className={s.dot}>7</div>
            <div className={s.block}>
              <div className={s.header}>Advertising campaign</div>
              <div className={s.hint}>
                We create and design pages in social networks, a website, create
                a content plan, set up and launch an advertising campaign
              </div>
            </div>
          </div>
          <div className={s.dotLine} />
          <div className={s.point}>
            <div className={s.dot}>6</div>
            <div className={s.block}>
              <div className={s.header}>Recruitment</div>
              <div className={s.hint}>
                We conduct interviews, select the best candidates, teach them
                both the technical basics and working with clients
              </div>
            </div>
          </div>
          <div className={s.dotLine} />
          <div className={s.point}>
            <div className={s.dot}>5</div>
            <div className={s.block}>
              <div className={s.header}>Technical activation</div>
              <div className={s.title}> 1 week</div>
              <div className={s.hint}>
                We build a local network, assemble and setting up computers,
                setting up servers, install software
              </div>
            </div>
          </div>
          <div className={s.leftDotLine} />
        </div>
        <div className={clsx(s.line, s.third)}>
          <div className={s.point}>
            <div className={s.dot}>9</div>
            <div className={s.block}>
              <div className={s.header}>Manager departure</div>
              <div className={s.title}>1-4 weeks</div>
              <div className={s.hint}>
                Our specialist comes to you to prepare, set up computers and
                launch the club
              </div>
            </div>
          </div>
          <div className={s.dotLine} />
          <div className={s.point}>
            <div className={clsx(s.dot, s.lastDot)}>10</div>
            <div className={s.block}>
              <div className={s.header}>Maintenance</div>
              <div className={s.hint}>
                Your personal manager is in touch 24/7 — always advises on the
                marketing and technical part
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
