import { FC } from 'react';

import cn from 'classnames'

import { DiscordIcon, MailIcon, PinIcon, TelegramIcon, TextLogoIcon, TwitterIcon } from 'icons';

import s from './Styles.module.scss';

interface IFooterProps {
  className?: string;
}

export const Footer: FC<IFooterProps> = ({ className }) => {

  const SOCIALS = [
    {
      img: <DiscordIcon width={34} className={s.icon} />,
      link: 'https://discord.gg/gn7wZABDRB',
    },
    {
      img: <TwitterIcon width={34} className={s.icon} />,
      link: 'https://twitter.com/greed_coin',
    },
    {
      img: <TelegramIcon width={34} className={s.icon} />,
      link: 'https://t.me/GameGreed',
    },
  ];

  return (
    <div className={cn(s.root, className)}>
      <div className={s.block}>
        <TextLogoIcon />© 2022 GameGreed FZC LLC
      </div>
      <div className={s.block}>
        <div className={s.line}>
          <PinIcon />
          Ajman Media City Free Zone, PO box 4422
        </div>
        <div className={s.line}>
          <MailIcon />
          <a
            href="mailto:support@greed.ltd"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={s.mail}>support@greed.ltd</div>
          </a>
        </div>
      </div>
      <div className={s.line}>
        {SOCIALS.map((social, index) => (
          <a
            href={social.link}
            rel="noreferrer noopener"
            target={'_blank'}
            key={`socials-${index}`}
          >
            {social.img}
          </a>
        ))}
      </div>
      <div className={cn(s.block, s.link)}>
        <div className={s.link}>Terms of use</div>
        <div className={s.link}>Privacy</div>
      </div>
      <div className={cn(s.block, s.link)}>
        <div className={s.link}>Cookies</div>
        <div className={s.link}>Refund policy</div>
      </div>
      <div className={s.line}></div>
    </div>
  );
};
