import { FC } from 'react';

import { ICommonIconProps } from './types';

export const TwitterIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 54,
  height = 54,
  viewBox = '0 0 54 54',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g clipPath="url(#clip0_3_62)">
      <path
        d="M27 0C12.0897 0 0 12.0897 0 27C0 41.9103 12.0897 54 27 54C41.9103 54 54 41.9103 54 27C54 12.0897 41.9103 0 27 0ZM39.9757 20.3525C39.9938 20.6357 39.9937 20.931 39.9937 21.2203C39.9937 30.0676 33.2558 40.2589 20.9431 40.2589C17.1462 40.2589 13.6266 39.156 10.6614 37.2576C11.2038 37.3179 11.7221 37.342 12.2766 37.342C15.4105 37.342 18.2913 36.2812 20.5875 34.4853C17.6464 34.425 15.1754 32.4964 14.3317 29.8446C15.3623 29.9953 16.2904 29.9953 17.3511 29.7241C15.8367 29.4164 14.4756 28.594 13.4989 27.3965C12.5222 26.1989 11.9902 24.7002 11.9933 23.1549V23.0705C12.8792 23.5708 13.9219 23.8781 15.0127 23.9203C14.0957 23.3092 13.3437 22.4812 12.8233 21.5098C12.3029 20.5384 12.0302 19.4536 12.0295 18.3516C12.0295 17.104 12.3549 15.965 12.9395 14.9766C14.6204 17.0458 16.7179 18.7381 19.0957 19.9436C21.4734 21.1491 24.0783 21.8408 26.7408 21.9737C25.7946 17.4234 29.1938 13.7411 33.2799 13.7411C35.2085 13.7411 36.9442 14.5487 38.1676 15.8504C39.6804 15.5672 41.1268 15.0007 42.4165 14.2413C41.9163 15.7902 40.8676 17.098 39.4754 17.9237C40.8254 17.779 42.1272 17.4054 43.3326 16.881C42.4225 18.219 41.2835 19.4063 39.9757 20.3525Z"
        fill="url(#paint0_linear_3_62)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3_62"
        x1="13.5272"
        y1="2.49899"
        x2="45.5795"
        y2="50.6318"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D63FF" />
        <stop offset="1" stopColor="#2D63FF" />
      </linearGradient>
      <clipPath id="clip0_3_62">
        <rect width="54" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
