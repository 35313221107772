import './App.css';
import { Layout } from 'layout'
import { Join, Main, Map, Plan, Process, Royalty, Stats } from './pages';
import { Gallery } from './pages/Gallery';


function App() {
  return (
    <Layout className="App">
      <Main />
      <Map />
      <Process />
      <Stats />
      <Royalty />
      <Gallery />
      <Plan />
      <Join/>
    </Layout>
  );
}

export default App;
