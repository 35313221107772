
import clsx from 'clsx';
import s from './Styles.module.scss';

export const Royalty = ({ prop }) => {
  console.log(prop);
    return (
      <div className={s.root}>
        <div className={s.header}>Royalties. For what?</div>
        <div className={s.content}>
          <div className={s.left}>
            <div className={clsx(s.block, s.glow1)}>
              <div className={s.title}>Full technical support</div>
              <div className={s.text}>
                24/7 support. You will never be left alone with a technical
                problem
              </div>
            </div>
            <div className={s.block}>
              <div className={s.title}>Marketing support</div>
              <div className={s.text}>
                Maintaining social network accounts, providing promotional
                materials, centralized promotions and loyalty program
              </div>
            </div>
            <div className={s.block}>
              <div className={s.title}>
                The opportunity to use our brand or create your own
              </div>
              <div className={s.text}>
                You can use our corporate identity developed by the design team.
                But this is not necessary. You can use your own visul and name,
                we will help you implement your ideas
              </div>
            </div>
          </div>
          <div className={s.right}>
            <div className={s.blocklg}>
              <div className={s.title}>REGULAR TOURNMENTS</div>
              <div className={s.text}>
                We have been holding professional tournaments since 2017. For
                the first time in Russia, a tournament was held among 60
                participating clubs
              </div>
              <ul className={s.list}>
                <li>
                  Free functionality for holding local tournaments within the
                  GameGreed platform
                </li>
                <li>Additional prize money for your own tournaments</li>
                <li>
                  We are the only franchise that can bring customers to the
                  club, because the GameGreed platform works all over the world
                </li>
              </ul>
            </div>
            <div className={clsx(s.block, s.glow2)}>
              <div className={s.title}>
                Software for managing your club on exclusive terms
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
