
import { imgh, imgsm1, imgsm2, imgsm3, imgsm4, imgw1, imgw2 } from './images';
import s from './Styles.module.scss';

export const Gallery = ({ prop }) => {
  console.log(prop);
    return (
      <div className={s.root}>
        <div className={s.block}>
          <img src={imgsm1} alt="imgsm1" />
          <img src={imgsm2} alt="imgsm2" />
        </div>
        <div className={s.block}>
          <img src={imgh} alt="imgh" />
        </div>
        <div className={s.block}>
          <div className={s.blocksm}>
            <img src={imgsm3} alt="imgsm3" />
            <img src={imgw1} alt="imgw1" />
          </div>
          <div className={s.blocksm}>
            <img src={imgw2} alt="imgw2" />
            <img src={imgsm4} alt="imgsm4" />
          </div>
        </div>
      </div>
    );
}
