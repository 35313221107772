import { Footer } from './Footer';
import { Header } from './Header';
import s from './Styles.module.scss'

export function Layout({ children }) {
  return (
    <div
      className={s.layout}
      >
      <Header />
      <main className={s.content}>
        {children}
      </main>
      <Footer />
    </div>
  );
}
