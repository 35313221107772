
import s from './Styles.module.scss';

export const Map = ({ prop }) => {
  console.log(prop);
    return (
      <div className={s.root}>
        <div className={s.video} />
        <div className={s.text}>
          <div className={s.block}>
            <div className={s.number}>30</div>
            <div className={s.title}>
              Clubs were opened by us in Russia, Kazakhstan and Uzbekistan
            </div>
          </div>
          <div className={s.block}>
            <div className={s.number} />
            <div className={s.title}>
              <span className={s.color}>950+</span> PC assembled <br />
              <span className={s.color}>250+</span> employees hired
            </div>
          </div>
        </div>
      </div>
    );
}
