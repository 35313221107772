import { FC } from 'react';

import { ICommonIconProps } from './types';

export const MenuIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2C24 3.10457 23.1046 4 22 4H2C0.89543 4 0 3.10457 0 2ZM0 22C0 20.8954 0.895431 20 2 20H12C13.1046 20 14 20.8954 14 22C14 23.1046 13.1046 24 12 24H2C0.89543 24 0 23.1046 0 22ZM2 10C0.895431 10 0 10.8954 0 12C0 13.1046 0.89543 14 2 14H22C23.1046 14 24 13.1046 24 12C24 10.8954 23.1046 10 22 10H2Z" fill="white"/>
  </svg>
);
