
import s from './Styles.module.scss';
import { logo, money, invoice, wallet, calendar } from './images';
import { Button } from 'components';

export const Stats = ({ prop }) => {
  console.log(prop);
    return (
      <div className={s.root}>
        <div className={s.line}>
          <div className={s.cost}>
            <img src={logo} alt="logo" />
            <div className={s.price}>10 000 $</div>
            <div className={s.hint}>The cost of the franchise</div>
          </div>
          <div className={s.block} style={{ marginTop: 45 }}>
            <div className={s.title}>from 75 000 $</div>
            <div className={s.text}> Open Attachments</div>
          </div>
        </div>
        <div className={s.line}>
          <div className={s.blocksm}>
            <img src={money} alt="money" className={s.logo} />
            <div className={s.title}>5% </div>
            <div className={s.text}>Royalties</div>
          </div>
          <div className={s.blocksm}>
            <img src={invoice} alt="invoice" className={s.logo} />
            <div className={s.title}>{'>'} 14 000 $ </div>
            <div className={s.text}>Monthly revenue</div>
          </div>
          <div className={s.blocksm}>
            <img src={wallet} alt="wallet" className={s.logo} />
            <div className={s.title}>{'>'} 5 000 $ </div>
            <div className={s.text}>Profit</div>
          </div>
          <div className={s.blocksm}>
            <img src={calendar} alt="calendar" className={s.logo} />
            <div className={s.title}>15-24 months </div>
            <div className={s.text}>Business payback</div>
          </div>
        </div>
        <div className={s.line}>
          <Button>Consult for free</Button>
        </div>
      </div>
    );
}
